.container {
    padding: 4.5rem;
}

.corporate_box {
    padding: 50px;
}

.laptop-size{
    transform: scale(2);
}

@media (max-width: 736px) {
    .container {
        padding: 1.5rem;
    }

    .corporate_box {
        padding: 25px;
    }

    .laptop-size{
        transform: scale(1.6);
    }

}


@media (min-width: 1300px) and (max-width: 3000px) {
    p {
        font-size: 19px;
    }
}

@media (min-width: 1300px) and (max-width: 3000px) {
    .DNA_video {
        overflow: hidden !important;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .container {
        padding: 2.5rem;
    }

    .arrow_steps {
        display: none;
    }

    .arrow_missplace-step2 {
        margin-top: 125px;
    }

    .arrow_missplace-step3 {
        margin-top: 120px;
    }
}

/* Loader */

@keyframes dotAnimation {

    0%,
    20% {
        content: "";
    }

    40% {
        content: ".";
    }

    60% {
        content: "..";
    }

    80%,
    100% {
        content: "...";
    }
}

.dots::after {
    content: "";
    animation: dotAnimation 3s infinite;
}